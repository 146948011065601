export const randomRange = (min,max) => {
    return Math.floor(Math.random() * (max - min +1) + min);
};


export const isJapanese = (value) => {
    return value === "jp"
};

export const universityCodeEnum = {
    TEZUKA: "TEZUKAYAMA",
    OTEMON: "OTEMON",
    OTHERS: "default",
    TEIKYO: "teikyo",
    KANAGAWA: "KANAGAWA"
};
export const getUniversityCodeFromDomain = () => {
    const hostname = window.location.hostname;
    console.log(hostname);
    switch(hostname) {
        case "tu.ist-reserve.space":
            return universityCodeEnum.TEZUKA;
        case "ors.ist-reserve.space":
            return universityCodeEnum.OTEMON;
        case "tko.ist-reserve.space":
        case "awstaging-ist-v3-tko.staging.siba-service.jp":
            return universityCodeEnum.TEIKYO;
        case "awstaging-ist-v3-jindai.staging.siba-service.jp":
        case "kanagawa-u.ist-reserve.space":
            return universityCodeEnum.KANAGAWA;
        default :
            return universityCodeEnum.OTHERS;
    }
};

/***
 * カスタムscssの取得用コードの追加
 */
export const getUniversityCustomScssCodeFromDomain = () => {
    const hostname = window.location.hostname;
    switch(hostname) {
        case "tko.ist-reserve.space":
        case "awstaging-ist-v3-tko.staging.siba-service.jp":
            return universityCodeEnum.TEIKYO;
        case "localhost":
        default :
            return universityCodeEnum.OTHERS;
    }
}